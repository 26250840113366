<script lang="ts" setup>
</script>

<template>
  <div>
    <HeaderMenuNav class="nav-no-hero" />
    <main
      class="bg-white pt-[72px] lg:pt-[96px] xl:pt-[112px]"
    >
      <slot />
    </main>
    <LazyFooterMain />
  </div>
</template>

<style>
</style>
